<template>
    <div class="loader-box">
        <h2>Merci de patienter quelques secondes...</h2>
        <span class="loader"></span>
        <h1>...Une programmation incroyable est en cours de chargement !</h1>
    </div>
</template>

<script>
export default {
    name: "LudinamLoader"
}
</script>

<style scoped>

</style>