import APIService from "./api.service";

class AnimationService {

    async getAnimationsCategories(pagination = {}) {
        let url = '/animation_categories?';
        let page = pagination.page != undefined ? pagination.page : 1;
        let itemsPerPage = pagination.itemsPerPage != undefined ? pagination.itemsPerPage : 20;
        url += '&page=' + page;
        if (itemsPerPage > 0) {
            url += '&itemsPerPage=' + itemsPerPage;
        }
        let order = pagination.order != undefined ? pagination.order : 'desc'
        url += '&order%5Bid%5D=' + order
        return await APIService.get(url);
    }

    async getAnimationsTypes(pagination = {}) {
        let url = '/animation_types?';
        let page = pagination.page != undefined ? pagination.page : 1;
        let itemsPerPage = pagination.itemsPerPage != undefined ? pagination.itemsPerPage : 20;
        url += '&page=' + page;
        if (itemsPerPage > 0) {
            url += '&itemsPerPage=' + itemsPerPage;
        }
        let order = pagination.order != undefined ? pagination.order : 'desc'
        url += '&order%5Bid%5D=' + order
        return await APIService.get(url);
    }

    async getAnimation(animationId) {
        let url = '/animations/' + animationId;
        return await APIService.get(url);
    }

    async getAnimations(pagination = {}, evenementId = false) {
        let url = '/animations?';
        let page = pagination.page != undefined ? pagination.page : 1;
        let itemsPerPage = pagination.itemsPerPage != undefined ? pagination.itemsPerPage : 300;
        url += '&page=' + page;
        if (itemsPerPage > 0) {
            url += '&itemsPerPage=' + itemsPerPage;
        }
        if (evenementId) {
            url += '&evenement.id=' + evenementId;
        }
        url+= '&visible=true'
        let order = pagination.order != undefined ? pagination.order : 'desc'
        url += '&order%5Bid%5D=' + order
        return await APIService.get(url);
    }
    async getLastUpdatedAnimation( evenementId = false) {
        let url = '/animations?';
        let page = 1;
        let itemsPerPage = 1;
        url += '&page=' + page;
        if (itemsPerPage > 0) {
            url += '&itemsPerPage=' + itemsPerPage;
        }
        if (evenementId) {
            url += '&evenement.id=' + evenementId;
        }
        url+= '&visible=true'
        url += '&order%5BupdatedAt%5D=desc'
        return await APIService.get(url);
    }

}

export default new AnimationService();