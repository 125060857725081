<template>
    <v-container class="text-center mt-10 pa-sm-5 ma-sm-1" :fluid="true">
        <v-dialog
                v-model="loading"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
        >
            <ludinam-loader v-if="loading"/>
        </v-dialog>
        <v-dialog v-model="dialog" transition="dialog-bottom-transition" class="mt-3"
                  :fullscreen="$vuetify.breakpoint.mobile" width="550px">
            <v-card v-if="selectedAnimation != null">
                <v-toolbar dark color="orange darken-4">
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ selectedAnimation.nom }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-img
                        :src="getFullURL(selectedAnimation.mediaObjectImage)"
                        aspect-ratio="2.75"
                >
                </v-img>
                <v-card-title>
                    <v-row>
                        <v-col cols="12" class="text-center orange--text"><b>{{
                            selectedAnimation.dateDebut| moment
                            }}</b> à
                            <strong>{{ selectedAnimation.dateFin| moment }}</strong></v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row v-html="selectedAnimation.descriptionComplet"></v-row>
                    <v-row>
                        <v-chip small color="indigo" text-color="white" class="mr-1">
                            <v-avatar>
                                <v-icon>mdi-map-marker</v-icon>
                            </v-avatar>
                            {{ formatLieu(selectedAnimation.localisation.site.slug)|truncate }}
                        </v-chip>
                        <v-chip small color="teal darken-1" text-color="white" class="mr-1">
                            <v-avatar>
                                <v-icon>mdi-shape</v-icon>
                            </v-avatar>
                            {{ selectedAnimation.animationType.nom }}
                        </v-chip>
                        <div v-for=" categorie in selectedAnimation.animationCategories">
                            <v-chip small color="blue" dark text-color="white" class="mr-1">
                                <v-avatar>
                                    <v-icon>mdi-star</v-icon>
                                </v-avatar>
                                {{ categorie.nom }}
                            </v-chip>
                        </div>
                        <div v-for=" intervenant in selectedAnimation.intervenants">
                            <v-chip small color="pink darken-3" text-color="white" class="mr-1">
                                <v-avatar>
                                    <v-icon>mdi-account</v-icon>
                                </v-avatar>
                                {{ intervenant|truncate }}
                            </v-chip>
                        </div>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-row justify="center" align="center" class="text-center">
            <h2>Les animations de cette édition</h2>
        </v-row>
        <v-row justify="center" align="center">
            <v-progress-circular
                    :size="300"
                    :width="10"
                    indeterminate
                    color="orange darken-4"
                    v-if="loading"
            >
                Le programme est en cours de chargement
            </v-progress-circular>
            <v-alert type="info" width="100%" class="mt-5"
                     v-if="!animations.length">
                Aucune animation n'est encore annoncée pour cette année.
            </v-alert>
            <v-row align="center" class="mt-5" v-else>
                <v-col cols="10" sm="4">
                    <v-btn color="orange darken-4" dark v-on:click="showFilter = !showFilter">
                        <v-icon left dark>{{ iconFilter }}</v-icon>
                        Filtres des animations
                    </v-btn>
                </v-col>
                <v-col cols="2">
                    <v-row no-gutters align="center">
                        <v-col class="caption">Vignettes</v-col>
                        <v-icon :color="!vueListe?'blue':''">mdi-image-multiple</v-icon>
                        <v-switch v-model="vueListe" color="blue"/>
                        <v-icon :color="vueListe?'blue':''">mdi-format-list-bulleted-square</v-icon>
                        <v-col class="caption">Liste</v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="6" no-gutters>
                    <b>Légende : </b>
                    <v-chip small color="indigo" text-color="white">
                        <v-avatar>
                            <v-icon>mdi-map-marker</v-icon>
                        </v-avatar>
                        Lieux
                    </v-chip>
                    <v-chip small color="blue" dark text-color="white">
                        <v-avatar>
                            <v-icon>mdi-star</v-icon>
                        </v-avatar>
                        Catégories
                    </v-chip>
                    <v-chip small color="teal darken-1" text-color="white">
                        <v-avatar>
                            <v-icon>mdi-shape</v-icon>
                        </v-avatar>
                        Types
                    </v-chip>
                    <v-chip small color="pink darken-3" text-color="white">
                        <v-avatar>
                            <v-icon>mdi-account</v-icon>
                        </v-avatar>
                        Intervenants
                    </v-chip>
                </v-col>
                <v-col cols="12" sm="6" v-show="showFilter">
                    <v-select
                            :items="events.filters.categorie"
                            item-text="nom"
                            item-value="@id"
                            label="Catégorie"
                            v-model="selectedCategorie"
                            attach
                            chips
                            multiple
                    >
                        <template v-slot:selection="{ item, index }">
                            <v-chip small color="indigo" dark close @click:close="removeCategorie(item['@id'])">
                                {{ item.nom |truncate }}
                            </v-chip>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" sm="6" v-show="showFilter">
                    <v-select
                            :items="events.filters.lieu"
                            item-text="nom"
                            item-value="slug"
                            label="Lieu"
                            v-model="selectedLieu"
                            attach
                            chips
                            multiple
                    >
                        <template v-slot:selection="{ item, index }">
                            <v-chip small color="blue" dark class="text-truncate" close
                                    @click:close="removeLieu(item.slug)">
                                {{ item.nom |truncate }}
                            </v-chip>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" sm="6" v-show="showFilter">
                    <v-select
                            :items="events.filters.type"
                            item-text="nom"
                            item-value="slug"
                            label="Type"
                            v-model="selectedType"
                            attach
                            chips
                            multiple
                    >
                        <template v-slot:selection="{ item, index }">
                            <v-chip small color="teal darken-1" dark class="text-truncate" close
                                    @click:close="removeType(item.slug)">
                                {{ item.nom |truncate }}
                            </v-chip>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-tabs
                    v-if="animations.length"
                    v-model="active"
                    dark
                    grow
                    centered
                    slider-color="orange"
                    class="mt-3"
            >
                <v-tab
                        v-for="(day) in events.days"
                        :key="day.dayStr"
                        ripple
                        class="ludi-blue"
                        dark

                >
                    {{ day.dayStr }}
                </v-tab>
                <v-tabs-items touchless v-model="active">
                    <v-tab-item
                            v-for="(day) in events.days"
                            :key="day.dayStr"
                    >
                        <v-container fluid v-if="!vueListe">
                            <v-row class="pa-0 ma-0" no-gutters>
                                <v-col cols="12" sm="6" lg="3" md="3" xl="2" class="pa-1 ma-0"
                                       v-for="(animation,index) in animationsForDate(day.date)"
                                       :key="index">
                                    <v-card @click.native="loadAnimation(animation.id)" style="cursor: pointer"
                                            v-if="animation.visible"
                                            width="100%" elevation="15">
                                        <v-img
                                                :src="getFullURL(animation.mediaObjectImage)"
                                                aspect-ratio="2.75"
                                        ></v-img>
                                        <v-card-title>
                                            <v-row align="center" justify="center">
                                                <v-col cols="12"
                                                       class="ma-0 pa-0 text-center not-more-than-3-lines orange--text"
                                                       align-self="center">
                                                    <strong>{{ animation.nom }}</strong>
                                                </v-col>
                                                <v-col cols="12" class="text-center ludi-blue white--text">
                                                    <strong>{{ animation.dateDebut| moment }}</strong> à <strong>{{
                                                    animation.dateFin| moment
                                                    }}</strong>
                                                    <br><span class="caption">{{ animation.localisation.nom }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row class="pt-3">
                                                <v-chip small color="indigo" text-color="white">
                                                    <v-avatar>
                                                        <v-icon>mdi-map-marker</v-icon>
                                                    </v-avatar>
                                                    {{ formatLieu(animation.localisation.site.slug)|truncate }}
                                                </v-chip>
                                                <v-chip small color="teal darken-1" text-color="white">
                                                    <v-avatar>
                                                        <v-icon>mdi-shape</v-icon>
                                                    </v-avatar>
                                                    <span class="text-capitalize">{{
                                                        formatType(animation.animationType.slug)|truncate
                                                        }}</span>
                                                </v-chip>
                                                <div v-for=" categorie in animation.animationCategories">
                                                    <v-chip small color="blue" dark text-color="white">
                                                        <v-avatar>
                                                            <v-icon>mdi-star</v-icon>
                                                        </v-avatar>
                                                        {{ formatCategorie(categorie)|truncate }}
                                                    </v-chip>
                                                </div>
                                                <div v-for="intervenant in animation.intervenants">
                                                    <v-chip small color="pink darken-3" text-color="white">
                                                        <v-avatar>
                                                            <v-icon>mdi-account</v-icon>
                                                        </v-avatar>
                                                        {{ intervenant|truncate }}
                                                    </v-chip>
                                                </div>
                                            </v-row>
                                        </v-card-text>
                                        <v-card-actions>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container fluid v-else>
                            <v-row class="pa-0 ma-0" no-gutters>
                                <v-simple-table style="width: 100%; min-height: 50vh" fixed-header>
                                    <template v-slot:default>
                                        <thead>
                                        <tr>
                                            <th class="text-center pl-1  pr-1">
                                                Visuel
                                            </th>
                                            <th class="text-center pl-1  pr-1">Début</th>
                                            <th class="text-center pl-1  pr-1">Fin</th>
                                            <th class="text-left pl-1  pr-1" style="width: 50%">Nom</th>
                                            <th class="text-center pl-1  pr-1">Lieu</th>
                                            <th class="text-center pl-1  pr-1">Type</th>
                                            <th class="text-center pl-1  pr-1">Catégories</th>
                                            <th class="text-center pl-1  pr-1">Intervenants</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                                v-for="(animation,index) in animationsForDate(day.date)"
                                                :key="index"
                                                @click="loadAnimation(animation.id)" style="cursor: pointer"
                                                v-if="animation.visible"
                                                class="mobile-td"
                                        >
                                            <td class="pl-1  pr-1">
                                                <v-avatar>
                                                    <v-img :src="getFullURL(animation.mediaObjectImage)"></v-img>
                                                </v-avatar>
                                            </td>
                                            <td class="pl-1  pr-1">{{ animation.dateDebut| moment }}</td>
                                            <td class="pl-1  pr-1">{{ animation.dateFin| moment }}</td>
                                            <td class="text-left pl-1  pr-1">{{ animation.nom }}</td>
                                            <td class="pl-1  pr-1">
                                                <v-chip small color="indigo" text-color="white">
                                                    <v-avatar>
                                                        <v-icon>mdi-map-marker</v-icon>
                                                    </v-avatar>
                                                    {{ formatLieu(animation.localisation.site.slug)|truncate }}
                                                </v-chip>
                                            </td>
                                            <td class="pl-1  pr-1">
                                                <v-chip small color="teal darken-1" text-color="white">
                                                    <v-avatar>
                                                        <v-icon>mdi-shape</v-icon>
                                                    </v-avatar>
                                                    <span class="text-capitalize">{{
                                                        formatType(animation.animationType.slug)|truncate
                                                        }}</span>
                                                </v-chip>
                                            </td>
                                            <td class="pl-1  pr-1">
                                                <div v-for=" categorie in animation.animationCategories">
                                                    <v-chip small color="blue" dark text-color="white">
                                                        <v-avatar>
                                                            <v-icon>mdi-star</v-icon>
                                                        </v-avatar>
                                                        {{ formatCategorie(categorie)|truncate }}
                                                    </v-chip>
                                                </div>
                                            </td>
                                            <td class="pl-sm-2 pr-sm-2  ">
                                                <div v-for="intervenant in animation.intervenants">
                                                    <v-chip small color="pink darken-3" text-color="white">
                                                        <v-avatar>
                                                            <v-icon>mdi-account</v-icon>
                                                        </v-avatar>
                                                        {{ intervenant|truncate }}
                                                    </v-chip>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                </v-tabs-items>
            </v-tabs>
        </v-row>
    </v-container>
</template>

<script>
import AnimationService from "../../services/animation.service"
import MediaObjectMixin from "../../mixins/MediaObjectMixin";
import LudinamLoader from "../LudinamLoader.vue";

export default {
    name: "Animations",
    components: {LudinamLoader},
    mixins: [MediaObjectMixin],
    data: () => ({
        active: null,
        animations: [],
        vueListe: false,
        loading: false,
        showFilter: false,
        events: {
            days: [],
            filters: {
                categorie: [],
                lieu: [],
                type: [],
            }
        },
        selectedLieu: [],
        selectedCategorie: [],
        selectedType: [],
        selectedAnimation: null,
        dialog: false,

    }),
    created() {
        this.loadAnimations();
        this.initDate();
        this.events.filters.lieu = this.$announcedEvent.sites
        AnimationService.getAnimationsCategories().then((response) => {
            this.events.filters.categorie = response.data['hydra:member']
        }).catch(e => {
            console.error(e);
        })
        AnimationService.getAnimationsTypes().then((response) => {
            this.events.filters.type = response.data['hydra:member']
        }).catch(e => {
            console.error(e);
        })
    },
    computed: {
        evenementsLoaded() {
            return this.$announcedEvent;
        },
        iconFilter() {
            if (this.showFilter) {
                return 'mdi-arrow-up-circle'
            } else {
                return 'mdi-arrow-down-circle'
            }
        },
    },
    methods: {
        loadAnimations() {
            if (this.$announcedEvent) {
                this.loading = true;
                let lastUpdatedTime = localStorage.getItem("lastUpdatedTime");
                let storedAnimations = JSON.parse(localStorage.getItem("ludinamAnimations"));
                if (!storedAnimations) {
                    lastUpdatedTime = false;
                } else {
                    this.animations = storedAnimations;
                }
                AnimationService.getLastUpdatedAnimation(this.$announcedEvent.id).then((response) => {
                    let newLastUpdatedAt = response.data['hydra:member'].length > 0 ? response.data['hydra:member'][0].updatedAt : null;
                    if (!lastUpdatedTime || (newLastUpdatedAt != null && (newLastUpdatedAt != lastUpdatedTime))) {
                        console.log("Il y a du neuf !")
                        localStorage.setItem("lastUpdatedTime", newLastUpdatedAt);
                        AnimationService.getAnimations({}, this.$announcedEvent.id).then((response) => {
                            this.animations = response.data['hydra:member'];
                            localStorage.setItem("ludinamAnimations", JSON.stringify(this.animations));
                            if (this.animations.length > 10) {
                                this.vueListe = true;
                            }
                        }).catch(e => {
                            console.error(e);
                        })
                    }
                }).finally(() => {
                    this.loading = false;
                    if (this.animations.length > 10) {
                        this.vueListe = true;
                    }
                })
            }
        },
        loadAnimation(animationId) {
            AnimationService.getAnimation(animationId).then(animation => {
                this.dialog = true;
                this.selectedAnimation = animation.data;
            })
        },
        animationsForDate(date) {
            let dateBegin = new Date(date.getTime());
            dateBegin.setHours(0, 0, 0);
            let dateEnd = new Date(date.getTime());
            dateEnd.setHours(23, 59, 59);
            return this.animations.filter((animation) => {
                if (this.selectedCategorie.length) {
                    let categorie = animation.animationCategories.some((categorie) => {
                        return this.selectedCategorie.includes(categorie)
                    })
                    if (!categorie) {
                        return false
                    }
                }
                if (this.selectedLieu.length) {
                    let location = this.selectedLieu.includes(animation.localisation.site.slug)
                    if (!location) return false;
                }
                if (this.selectedType.length) {
                    let type = this.selectedType.includes(animation.animationType.slug)
                    if (!type) return false;
                }
                let start = new Date(animation.dateDebut);
                let end = new Date(animation.dateFin);
                if (date instanceof Date) {
                    return start.getTime() >= dateBegin.getTime() && end.getTime() <= dateEnd.getTime()
                }
                return false;
            }).sort((animUn, animDeux) => {
                let startUn = new Date(animUn.dateDebut);
                let startDeux = new Date(animDeux.dateDebut);
                return startUn.getTime() > startDeux.getTime() ? 1 : -1;
            });
        },
        formatLieu(slug) {
            let lieuToFound = this.events.filters.lieu.find(lieu => lieu.slug === slug)
            if (lieuToFound === undefined) {
                console.error(slug + ' non trouvé')
                return slug ? slug : '';
            } else {
                return lieuToFound.nom ? lieuToFound.nom : '';
            }
        },
        formatCategorie(slug) {
            let category = this.events.filters.categorie.find(categorie => categorie['@id'] === slug);
            return category && category.nom ? category.nom : ''
        },
        formatType(slug) {
            return slug
        },
        initDate() {
            let dateStart = new Date(this.$announcedEvent.dateDebut);
            dateStart.setHours(0, 0);
            let dateEnd = new Date(this.$announcedEvent.dateFin);
            dateEnd.setHours(23, 59, 59);
            let difference = dateEnd.getTime() - dateStart.getTime();
            let days = Math.ceil(difference / (1000 * 3600 * 24));
            let currDate = dateStart;
            for (let i = 0; i < days; i++) {
                let currDateStr = currDate.toLocaleString(window.navigator.language, {weekday: 'long'});
                this.events.days.push({dayStr: currDateStr, date: new Date(currDate.getTime())});
                currDate.setDate(currDate.getDate() + 1);
            }
        },
        removeCategorie(item) {
            this.selectedCategorie.splice(this.selectedCategorie.indexOf(item), 1)
            this.selectedCategorie = [...this.selectedCategorie]
        },
        removeLieu(item) {
            this.selectedLieu.splice(this.selectedLieu.indexOf(item), 1)
            this.selectedLieu = [...this.selectedLieu]
        },
        removeType(item) {
            this.selectedType.splice(this.selectedType.indexOf(item), 1)
            this.selectedType = [...this.selectedType]
        },
    },
    watch: {
        selectedAnimation: function () {
            if (this.selectedAnimation) {
                this.dialog = true;
            }
        }
    }
};
</script>

<style lang="scss" scoped>

.not-more-than-3-lines {
  line-height: 1.4em;
  height: 4.5em;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  font-size: 1.1em;
}
</style>
